<template>
  <div class="saleDaily">
    <h1>销售日汇总</h1>
    <el-form :inline="true">
      <el-form-item label="发货日期">
        <el-date-picker :clearable="false" v-model="searchParam.sendDates" type="daterange" start-placeholder="起" end-placeholder="止" style="width: 220px;" />
      </el-form-item>
      <el-form-item label="仓储">
        <el-select v-model="searchParam.storage" filterable style="width: 120px;" clearable>
          <el-option v-for='storageItem in show.storageList' :key="storageItem.id" :label="storageItem.value" :value="storageItem.value" />
        </el-select>
      </el-form-item>
      <el-form-item label="客户">
        <el-select v-model="searchParam.custom" filterable style="width: 120px;" clearable>
          <el-option v-for="customItem in show.customList" :key="customItem.id" :label="customItem.value" :value="customItem.value" />
        </el-select>
      </el-form-item>
      <el-form-item label="到货状态">
        <el-select v-model="searchParam.hasReceive" filterable style="width: 120px;" clearable>
          <el-option :key="0" label="未到货" :value="false" />
          <el-option :key="1" label="已到货" :value="true" />
        </el-select>
      </el-form-item>
      <el-form-item label="">
        <el-switch v-model="searchParam.hasStorage" active-text="区分仓储" />
      </el-form-item>
      <el-form-item label="">
        <el-switch v-model="searchParam.hasCustom" active-text="区分客户" />
      </el-form-item>
      <el-form-item label="">
        <el-switch v-model="searchParam.hasCustomNote" active-text="区分销售品名" />
      </el-form-item>
      <el-form-item>
        <el-button-group>
          <el-button type="primary" @click="commitFindList()">搜索</el-button>
          <el-button @click="resetSearchParam()">重置</el-button>
        </el-button-group>
      </el-form-item>
    </el-form>
    <el-table :data="data.list" border show-summary :summary-method="summary" :span-method="handleSpanMethod" height="calc(100vh - 310px)" @cell-mouse-enter="() => { }">
      <el-table-column prop="storage" label="仓储" show-overflow-tooltip v-if="show.tableStorage" />
      <el-table-column prop="custom" label="客户" show-overflow-tooltip v-if="show.tableCustom" />
      <el-table-column prop="customNote" label="销售品名" show-overflow-tooltip v-if="show.tableCustomNote" />
      <el-table-column prop="transportPrice" label="运费单价" show-overflow-tooltip align="right">
        <template #default="scope">
          {{ math.formatNumber(scope.row.transportPrice) }}
        </template>
      </el-table-column>
      <el-table-column prop="truckQuantity" label="车数" show-overflow-tooltip align="right">
        <template #default="scope">
          {{ math.formatNumber(scope.row.truckQuantity) }}
        </template>
      </el-table-column>
      <el-table-column prop="sendQuantity" label="发货数量" show-overflow-tooltip align="right">
        <template #default="scope">
          {{ math.formatNumber(scope.row.sendQuantity) }}
        </template>
      </el-table-column>
    </el-table>
    <div style="height: 10px;" />
  </div>
</template>

<script setup>
import { reactive, ref } from 'vue'
import { ElMessage, ElMessageBox } from 'element-plus'
import api from '../../apis/api'
import util from '../../utils/util'
import math from '../../utils/math'

const data = reactive({
  list: [],
  sendQuantity: null,
  truckQuantity: null,
})

const show = reactive({
  selected: [],
  customList: [],
  storageList: [],
  tableStorage: true,
  tableCustom: true,
  tableCustomNote: true,
})
api.get('/backend/dict/getByCode', { params: { code: 'custom' } }).then(res => {
  show.customList = res.dictList
})
api.get('/backend/dict/getByCode', { params: { code: 'storage' } }).then(res => {
  show.storageList = res.dictList
})

const now = new Date()
const yesterday = new Date(now.getTime() - 1 * 24 * 60 * 60 * 1000)

const searchParam = reactive({})
const resetSearchParam = () => {
  searchParam.sendDates = [yesterday, yesterday]
  searchParam.hasReceive = null
  searchParam.custom = null
  searchParam.storage = null
  searchParam.hasStorage = true
  searchParam.hasCustom = true
  searchParam.hasCustomNote = true
}
resetSearchParam()

const commitFindList = () => {
  if (!searchParam.sendDates || searchParam.sendDates.length != 2) {
    ElMessage.error('请选择日期')
    return
  }
  data.list = []
  const params = {}
  params.sendDateGe = util.parseTime(searchParam.sendDates[0], '{y}-{m}-{d}')
  params.sendDateLe = util.parseTime(searchParam.sendDates[1], '{y}-{m}-{d}')
  params.hasReceive = searchParam.hasReceive
  params.custom = searchParam.custom
  params.storage = searchParam.storage
  params.hasStorage = searchParam.hasStorage
  params.hasCustom = searchParam.hasCustom
  params.hasCustomNote = searchParam.hasCustomNote
  show.tableStorage = params.hasStorage
  show.tableCustom = params.hasCustom
  show.tableCustomNote = params.hasCustomNote
  api.get('/backend/sale/daily', { params: params }).then(res => {
    data.list = res.list
    data.sendQuantity = res.sendQuantity
    data.truckQuantity = res.truckQuantity
  })
}
commitFindList()

const summary = () => {
  return ['合计', '', '', '', math.formatNumber(data.truckQuantity), math.formatNumber(data.sendQuantity)]
}

const handleSpanMethod = ({ rowIndex, columnIndex }) => {
  const columnIndexs = [0, 1, 2] // 合并这些列
  // 不合并的列
  if (!columnIndexs.includes(columnIndex)) {
    return [1, 1]
  }
  // 被合并，不展示
  const row = data.list[rowIndex]
  if (rowIndex > 0) {
    const lastrow = data.list[rowIndex - 1]
    if (columnIndex == 0 && row.storage == lastrow.storage) {
      return [0, 0]
    }
    if (columnIndex == 1 && row.storage == lastrow.storage && row.custom == lastrow.custom) {
      return [0, 0]
    }
    if (columnIndex == 2 && row.storage == lastrow.storage && row.custom == lastrow.custom && row.customNote == lastrow.customNote) {
      return [0, 0]
    }
  }
  // 计算重复 合并
  let span
  for (span = 1; rowIndex + span < data.list.length; span++) {
    const nextrow = data.list[rowIndex + span]
    if (columnIndex == 0 && row.storage != nextrow.storage) {
      break
    }
    if (columnIndex == 1 && (row.storage != nextrow.storage || row.custom != nextrow.custom)) {
      break
    }
    if (columnIndex == 2 && (row.storage != nextrow.storage || row.custom != nextrow.custom || row.customNote != nextrow.customNote)) {
      break
    }
  }
  return [span, 1]
}
</script>

<style lang="less">
.saleDaily {
  .hover-cell {
    background-color: #f5f7fa;
    color: #909399;
    cursor: pointer;
  }
}
</style>